import React from "react";
import Artykul from "../components/artykul";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/pierwsze-kroki-w-CRM.jpg";

export const query = graphql`
  query {
    zdjecie1: file(relativePath: { eq: "kalendarz.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(relativePath: { eq: "wiazanie-zadania.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(relativePath: { eq: "wiazanie-zadan.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(relativePath: { eq: "lista-aktywnosci.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(relativePath: { eq: "artybuty.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie6: file(relativePath: { eq: "szansa-sprzedazy.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie7: file(relativePath: { eq: "lejek.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie8: file(relativePath: { eq: "system-crm-Baner-testuj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const PierwszeKrokiwCRM = ({ data }) => {
  return (
    <Artykul
      title="Pierwsze kroki w CRM"
      articleImage={obrazekArtykulu}
      keywords={["pierwsze kroki w crm"]}
      articleImageAlt="Pierwsze kroki w CRM"
      metaTitle="Pierwsze kroki w CRM. Jak rozpocząć pracę?"
      metaDescription="Zastanawiasz się, jak rozpocząć pracę z CRM? Pierwsze kroki w CRM ✅ Wskazówki dla początkujących. QuickCRM przez 30 dni za darmo!"
    >
      <br />

      <p>
        Chcesz rozpocząć swoją przygodę z CRM we właściwy i przemyślany sposób?
        Aby Ci w tym pomóc, przygotowaliśmy praktyczny poradnik pt. „Pierwsze
        kroki w CRM”. Ma pomóc Ci wykorzystać całkowity jego potencjał i
        osiągnąć pierwsze, wymierne efekty.
      </p>
      <h2>Pięć kroków do sukcesu</h2>
      <p>
        Poniżej znajdziesz pięć najważniejszych obszarów, w których możesz
        wykorzystać oprogramowanie CRM już na samym początku. Z materiału
        poznasz praktyczne sposoby na:
        <br />
        <ol>
          <li>Efektywne planowanie zadań</li>
          <li>Budowanie bazy kontrahentów</li>
          <li>Zarządzanie procesem sprzedaży</li>
          <li>Mierzenie wyników</li>
          <li>Szybką konfigurację pulpitu</li>
        </ol>
      </p>
      <br />
      <h3>1. Efektywne planowanie zadań</h3>
      <p>
        Praca na zadaniach to jedna z podstawowych, ale bardzo ważnych funkcji
        systemu CRM. To nie tylko interaktywny kalendarz z zadaniami, ale całe
        narzędzie do planowania pracy i zapisywania informacji o postępach.
      </p>
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto",
          maxWidth: 600
        }}
        title="Powiazanie zadan z szansa sprzedazy - pierwsze kroki w crm"
        alt="Powiazanie zadan z szansa sprzedazy - pierwsze kroki w crm"
        fluid={data.zdjecie1.childImageSharp.fluid}
      />
      <br />
      <h4>Kalendarz w QuickCRM to</h4>
      <p>
        <li>możliwość podejrzenia zadań pozostałych pracowników,</li>
        <li>lista wszystkich aktywności widoczna dla kierownika,</li>
        <li>zadania na dziś na pulpicie pracownika,</li>
        <li>przypomnienia o zadaniach,</li>
        <li>wiązanie zadań.</li>
      </p>
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto",
          maxWidth: 600
        }}
        title="Powiazanie zadan z szansa sprzedazy - pierwsze kroki w crm"
        alt="Powiazanie zadan z szansa sprzedazy - pierwsze kroki w crm"
        fluid={data.zdjecie2.childImageSharp.fluid}
      />
      <br />
      <h4>Czym jest wiązanie zadań?</h4>
      <p>
        Dzięki niemu każde zdarzenie (np. telefon od klienta) i zadanie (np.
        spotkanie) może być podpięte do klienta i szansy sprzedaży. Masz dzięki
        temu całą historię relacji między Twoją firmą a klientem.
      </p>
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        title="Powiazanie zadan z szansa sprzedazy - pierwsze kroki w crm"
        alt="Powiazanie zadan z szansa sprzedazy - pierwsze kroki w crm"
        fluid={data.zdjecie3.childImageSharp.fluid}
      />
      <br />
      <p>
        Tym, co wyróżnia kalendarz w QuickCRM jest możliwość ustawienia
        dowolnych typów zadań, a także automatyczne raporty oparte o aktywności
        pracowników.
      </p>
      <h4>Pierwsze kroki w CRM: kalendarz</h4>
      <p>
        <br />
        <ol>
          <li>
            Zapoznaj się z dostępnymi typami zadań i określ, czy są
            wystarczające.
          </li>
          <li>Jeśli nie – dodaj własne.</li>
          <li>
            Pamiętaj o wiązaniu zadań z szansami sprzedaży i kontrahentami – na
            końcu artykułu dowiesz się, jakie to ważne!
          </li>
          <li>Wyświetl zadania na dziś na pulpicie pracowników</li>
        </ol>
      </p>
      <p>
        Pomoc: <Link to="/pomoc/zadania-i-zdarzenia/">zadania i zdarzenia</Link>
      </p>
      <br />
      <h4>Planuj spotkania z łatwością!</h4>
      <p>
        QuickCRM sprawia, że planowanie spotkań wewnątrz firmy staje się dużo
        łatwiejsze. Wystarczy, że wyświetlisz na kalendarzu zadania danych osób
        i sprawdzisz, kiedy każdy z nich będzie miał czas. Dodasz zadanie i w
        polu ‘wykonawca’ wpiszesz nazwiska. Jednym kliknięciem utworzysz dla
        każdego z nich zadanie, które natychmiast zobaczą w swoim kalendarzu.
      </p>
      <br />
      <h4>Zadania i zdarzenia: funkcje dla kierownika</h4>
      <p>
        W CRM kierownik może monitorować aktywność całej firmy. Może wyświetlić
        listę wszystkich zadań i zadań poszczególnych pracowników. Dodatkowo,
        zdarzenia pozwalają mu ocenić skuteczność poszczególnych działań (pkt
        4.).
      </p>
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        title="Powiazanie zadan z szansa sprzedazy - pierwsze kroki w crm"
        alt="Powiazanie zadan z szansa sprzedazy - pierwsze kroki w crm"
        fluid={data.zdjecie4.childImageSharp.fluid}
      />
      <br />
      <p>
        <h3>2. Budowanie bazy kontrahentów</h3>
        System CRM ma służyć przede wszystkim tworzeniu i utrzymywaniu
        pozytywnych relacji z klientami. Zbieranie i przechowywanie informacji o
        współpracy z nimi jest więc jego kluczowym zadaniem.
      </p>
      <p>
        <br />
        <h4>Baza wiedzy o klientach w QuickCRM to</h4>
        <li>spójna komunikacja z klientem,</li>
        <li>bezpieczeństwo danych,</li>
        <li>CRM online - dostęp z każdego urządzenia,</li>
        <li>autouzupełnianie (integracja z GUS),</li>
        <li>segmentacja klientów.</li>
        <br />
        <h4>Jak działa segmentacja klientów?</h4>
      </p>
      <p>
        Atrybuty kontrahentów służą określaniu ich unikalnych cech, które służą
        segmentacji. Pozwala to odnaleźć klientów o dowolnych cechach (np.
        wszystkich firm usługowych z segmentu MŚP w Twojej bazie – atrybut
        ‘rodzaj działalności’ i atrybut ‘rozmiar firmy’).
      </p>
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto",
          maxWidth: 500
        }}
        title="system crm - pierwsze kroki w crm"
        alt="system crm - pierwsze kroki w crm"
        fluid={data.zdjecie5.childImageSharp.fluid}
      />
      <br />
      <p>
        <h4>Pierwsze kroki w CRM: baza wiedzy o klientach</h4>
        <ol>
          <li>Sprawdź, jak dodać kontrahenta do bazy.</li>
          <li>Zastanów się, jakie informacje o klientach chcesz zbierać.</li>
          <li>Zobacz, jak dodawać atrybuty kontrahentów.</li>
          <li>Dodaj swoich klientów lub zaimportuj bazę z pliku.</li>
        </ol>
        <br />
      </p>
      <p>
        Pomoc: <Link to="/pomoc/kontrahenci/"> kontrahenci</Link>
      </p>
      <br />
      <h4>Dlaczego warto zarządzać wiedzą o klientach?</h4>
      <p>
        CRM daje dostęp do wiedzy każdemu pracownikowi, niezależnie od miejsca
        czy urządzenia, z którego korzysta. Nie stracisz ich też wraz z
        odejściem handlowca, bo nie będą przechowywane na jego dysku czy w
        formie papierowych notatek. Baza wiedzy pomaga też z łatwością wdrażać
        nowych pracowników i zastępować nieobecnych.
      </p>
      <br />
      <h4>Zastępowanie pracowników</h4>
      <p>
        Musisz szybko zastąpić handlowca, który zachorował i nie przyszedł do
        pracy. Jeden z jego klientów z nich dzwoni do Twojej firmy. Co robisz?
        Nie mając systemu CRM, wszystkie informacje o tym kliencie i jego
        sytuacji przechowuje dysk (lub umysł) nieobecnego handlowca, a
        zastępujący go pracownik stoi przed zadaniem obsługi klienta, o którym
        nic nie wie. Chciałbyś dać pracownikom platformę do dzielenia się
        informacjami? Jest nią właśnie baza kontrahentów w CRM.
        <br />
        <br />
      </p>
      <h3>3. Zarządzanie procesem sprzedaży</h3>
      <p>
        Proces sprzedaży to pojęcie szerokie i na pewno znajdziesz na jego temat
        szczegółowe informacje (np. na naszym blogu). W ramach tego punktu
        omówimy jedynie kampanie sprzedażowe, czyli narzędzie służące do obsługi
        i monitorowania procesu sprzedaży.
      </p>
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        title="system crm - pierwsze kroki w crm"
        alt="system crm - pierwsze kroki w crm"
        fluid={data.zdjecie6.childImageSharp.fluid}
      />
      <br />
      <p>
        Dzięki niemu każdy pracownik będzie wiedział, jakie cele musi osiągnąć w
        rozmowie z klientem. Pozwoli też na zbieranie danych i tworzenie
        raportów, o których opowiemy w następnym kroku.
      </p>
      <br />
      <h4>Kampanie sprzedażowe w QuickCRM to</h4>
      <p>
        <li>możliwość tworzenia wielu kampanii,</li>
        <li>zarządzanie szansami sprzedaży,</li>
        <li>własne etapy i cele na etapach każdej kampanii,</li>
        <li>raporty dla kierownika,</li>
        <li>wskazówki dla handlowca!</li>
        <br />
      </p>
      <h4>Jak działają wskazówki dla handlowca?</h4>
      <p>
        Dla każdej szansy Twoi handlowcy planują i wykonują zadania, takie jak
        kontakt z klientem czy wysłanie oferty. Ikony przy szansach wskazują na
        jej status. To znaczy, informują o tym, że wobec szansy są zaplanowane
        działania lub że należy je zaplanować. Dają też znać, jeśli zaplanowane
        zadanie nie zostało wykonane w terminie .
      </p>
      <br />
      <h4>Zarządzanie kampaniami sprzedaży</h4>
      <p>
        QuickCRM oferuje domyślną kampanię, z której możesz korzystać już od
        pierwszego dnia. Dodaj szanse sprzedaży, dla określonych klientów z
        Twojej bazy i zaplanuj wobec nich zadania. W ten sposób rozpoczniesz
        zarządzanie sprzedażą w CRM. Jeśli chcesz, możesz też dodać własną
        kampanię i ustalić dla niej indywidualne etapy i cele.
        <br />
        Pomoc: <Link to="/pomoc/szanse-sprzedazy/">kampanie sprzedaży</Link>
      </p>
      <br />
      <h4>Pierwsze kroki w CRM: kampanie sprzedaży</h4>
      <p>
        <ol>
          <li>
            Zapoznaj się z domyślną kampanią i określ, czy odpowiada Twojemu
            procesowi.
          </li>
          <li>Jeśli nie – dodaj własną kampanię lub kilka kampanii.</li>
          <li>Dodawaj kolejne szanse, łącząc je z klientami z Twojej bazy.</li>
          <li>Wobec każdej szansy zaplanuj zadania.</li>
          <li>Zacznij monitorować swój proces!</li>
        </ol>
        <br />
        <strong>
          Kierownik lub manager sprzedaży także może skorzystać z tej funkcji.
          Jak?
        </strong>
        <br />
        Zarządzanie procesem w taki sposób pozwala na mierzenie jego
        efektywności. Dzięki temu, kierownik ma dostęp do raportów, które dają
        możliwość szybkiej oceny sytuacji i reagowania na zmiany.
      </p>
      <p>
        <br />
        <h3>4. Mierzenie wyników</h3>
        <strong>
          Monitorowanie sprzedaży jest bardzo ważnym elementem strategii
          biznesowej przedsiębiorstwa.
        </strong>{" "}
        Tak naprawdę wszystkie poprzednie kroki prowadziły Cię do tego miejsca.
        Dzięki ich wykonaniu zebrałeś wiedzę, która jest niezbędna do
        usprawnienia funkcjonowania Twojej firmy, a właśnie to stanowi główny
        cel korzystania z CRM.
      </p>
      <br />
      <h4>Dlaczego warto wykorzystać automatyczne raporty CRM?</h4>
      <p>
        <li>
          Po pierwsze, oszczędza to czas poświęcany na zbieranie danych
          porozrzucanych po różnych miejscach. Bez CRM zestawienie ich ze sobą i
          zebranie do analizy jest trudnie i czasochłonne.
        </li>
        <li>
          Po drugie, ogranicza występowanie błędów. Ręczne tworzenie raportów
          generuje pole do drobnych pomyłek, których wyłapanie może przyprawić o
          ból głowy nawet najwytrwalszego managera.
        </li>
        <li>
          W końcu, takie analizy mają sens, jeśli przeprowadza się je
          regularnie. Jednak ręcznie zebrane dane szybko tracą na aktualności i
          w każdym tygodniu/miesiącu/kwartale cały proces zbierania, zestawiania
          i analizy trzeba zaczynać od nowa.
        </li>
        <br />
        CRM może każdego dnia dostarczać kierownikowi informacji o przebiegu
        sprzedaży i aktywności handlowców w formie automatycznych raportów.
        Sprawi, że wiedza ta będzie zawsze aktualna i łatwo dostępna dla każdego
        uprawnionego pracownika. Więcej o raportach{" "}
        <Link to="/pomoc/raporty/">tutaj</Link>.
      </p>
      <br />
      <h4>Raporty w QuickCRM to</h4>
      <p>
        <li>automatyzacja zbierania danych,</li>
        <li>pewność ich prawdziwości i aktualności,</li>
        <li>oszczędność czasu i ograniczenie pomyłek,</li>
        <li>narzędzia do analizy działań sprzedaży.</li>
        <br />
        <strong>Jak analizować działania?</strong>
        <br />
        Analiza działań sprzedaży jest możliwa dzięki wykorzystaniu
        najważniejszych raportów. Każdy z nich odpowiada na ważne pytania:
        <li>
          lejek sprzedażowy – ile szans znajduje się na każdym z etapów i jaka
          jest ich wartość?
        </li>
        <li>
          konwersja lejka – jaka część szans przechodzi z jednego etapu do
          drugiego?
        </li>
        <li>
          przyczyny utraty – jakie są najczęstsze przyczyny utraty szans
          sprzedaży?
        </li>
        <li>
          aktywność handlowców – jakie działania najczęściej wykonują
          pracownicy?
        </li>
        <br />
        <cite>
          Przykład: z raportu lejek sprzedaży (przykład 1.) wynika, że na etapie
          analizy potrzeb klienta jest aż 12 szans, a w następnym etapie jest
          ich tylko 5. To może oznaczać, że handlowcy chętniej zajmują się
          wystawianiem ofert i zamykaniem transakcji niż zadawaniem pytań i
          poznawaniem potrzeb klientów.
        </cite>
      </p>
      <p>
        <Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="system crm - pierwsze kroki w crm"
          alt="system crm - pierwsze kroki w crm"
          fluid={data.zdjecie7.childImageSharp.fluid}
        />
        <br />
        Możesz się upewnić i sprawdzić dodatkowo raport aktywność handlowców.
        Pokaże Ci on, jakich typów zadań podejmują się poszczególni handlowcy.
        Dzięki temu ocenisz, dlaczego doszło do takiej sytuacji i jak możesz to
        poprawić.
        <br />
        <h3>5. Szybka konfiguracja pulpitu</h3>
      </p>
      <p>
        Efektywne wykonywanie obowiązków wymaga także uporządkowanego miejsca
        pracy. Niezależnie od tego czy jest to biurko pracownika, czy jego
        pulpit w CRM, warto zadbać o odpowiednią organizację tego miejsca.
        Poświęcenie czasu na zaprojektowanie pulpitów zwróci się wielokrotnie w
        postaci wzrostu efektywności działu sprzedaży.
      </p>
      <br />
      <h4>Pulpit w QuickCRM to</h4>
      <p>
        <li>wyświetlanie zadań na dziś,</li>
        <li>tworzenie zakładek na pulpicie,</li>
        <li>możliwość dodawania widgetów,</li>
        <li>funkcja domyślnego pulpitu.</li>
      </p>
      <br />
      <h4>Pierwsze kroki w CRM: konfiguracja pulpitu</h4>
      <p>
        <ol>
          <li>
            Określ, jakich funkcji będą potrzebować poszczególni pracownicy
            (poniżej przykłady).
          </li>
          <li>
            Dodaj odpowiednie widgety i/lub skróty dla każdego typu pracownika
            (np. handlowiec i kierownik)
          </li>
          <li>Jeśli potrzebujesz więcej miejsca, utwórz zakładki.</li>
          <li>Ustaw pulpit domyślny dla każdego typu pracownika.</li>
        </ol>
      </p>
      <br />
      <h4>Przykładowy pulpit handlowca</h4>
      <p>
        Zastanówmy się, jakich narzędzi potrzebuje każdy handlowiec?
        <br />
        Przede wszystkim powinien mieć dostęp do swoich zadań – wykorzystaj
        widget moje zadania na dziś, aby dać mu wgląd w zaplanowane na dany
        dzień czynności. Oprócz tego, warto umieścić na pulpicie także
        kalendarz, który pozwoli mu na szybkie planowanie zadań i rejestrowanie
        zdarzeń. Daj mu także dostęp do szybkiego tworzenia nowych szans
        sprzedaży i kontrahentów.
        <br />
        Ważne: Raz skonfigurowany pulpit możesz ustawić jako domyślny dla danego
        typu użytkownika, dzięki czemu nie będziesz musiał robić tego dla
        każdego pojedynczego pracownika, a np. raz dla wszystkich specjalistów i
        raz dla managerów:
        <br />
        Pomoc: <Link to="/pomoc/dynamiczny-pulpit/">konfiguracja pulpitu</Link>
      </p>
      <h4>Przykładowy pulpit managera</h4>
      <p>
        Co warto umieścić na widoku kierownika?
        <br />
        Ważny będzie dla niego szybki dostęp do raportów, które będą się same
        aktualizować. W ten sposób będzie mógł monitorować np. konwersję lejka
        sprzedaży lub bieżące przyczyny utraty szans. Możesz dodać tam także
        listę wszystkich aktywności handlowców. Oczywiście manager może
        korzystać także z pozostałych widgetów, także tych, które wskazaliśmy
        jako przeznaczone dla handlowca.
      </p>
      <h4>Pierwsze chwile z QuickCRM</h4>
      <p>
        Opisane wyżej pierwsze kroki w CRM pozwolą Ci sprawnie rozpocząć pracę z
        tym narzędziem i osiągnąć pierwsze efekty w postaci lepiej
        zorganizowanego procesu sprzedażowego. Te działania pomogą Ci na
        skuteczne zarządzanie relacjami z klientem i wspieranie efektywności
        Twojego działu handlowego podczas jego codziennej pracy. Jeśli
        potrzebujesz więcej wskazówek, zajrzyj do działu{" "}
        <Link to="/pomoc/">POMOC</Link>.
      </p>

      <Link to="/demo-crm/">
        <Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="Testuj System CRM - pierwsze kroki w crm"
          alt="Testuj System CRM - pierwsze kroki w crm"
          fluid={data.zdjecie8.childImageSharp.fluid}
        />
      </Link>
    </Artykul>
  );
};

export default PierwszeKrokiwCRM;
